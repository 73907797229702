import { PageProps } from 'gatsby';
import { MediaData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/List/Card/Media';
import Item from '~/components/List/Item/Media';
import List from '~/containers/Espace/List';
import params from '~/params/media.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const PageAdminMedias: FC<
  PageProps & UserProps & { pageContext: { tab?: number } }
> = ({ location, user, pageContext: { tab } }) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <List
        displayArchive={false}
        itemGrid={Card}
        itemList={Item}
        model={
          new MediaData({
            params,
            wheres: {
              parent: '',
            },
          })
        }
        removeAddButton
        search={location.search}
        tabIndex={tab}
        title={t('medias.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminMedias, 'admin');
